import React, { Fragment, ReactElement } from 'react';
import { graphql } from 'gatsby';
import { Props } from '@types';

import Layout from '../components/layout/layout';
import SEO from '../components/seo/seo';

const NotFoundPage: React.FC<Props> = ({
  data,
  location,
}: Props): ReactElement => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location} title={siteTitle}>
      <>
        <SEO title="404: Not Found" />
        <h1>404: Not Found</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </>
    </Layout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
